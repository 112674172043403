import './about.scss';

const About = ({ service, image, content }) => {
  return (
    <section className="about">
      <div className="container">
        <div className="container__inner">
          <div className="about__content">
            <h2 className="section-title">{service} Experts</h2>

            <div className="about__text" dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <div className="mobile-image">
            <img src={image} alt="About us" />
          </div>
          <div
            className="about__image"
            style={{
              backgroundImage: `url('${image}')`,
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default About;
