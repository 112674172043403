import { Link } from 'react-router-dom';

import './home.scss';

const HomeScreen = ({ locations, logo, brand }) => (
  <section className="home">
    <div className="container">
      <div className="home__inner">
        <img src={logo} alt={brand} />

        <p>We work in these locations:</p>

        <ul>
          {locations.map(({ id, slug, title }) => (
            <li key={id}>
              <Link to={slug}>{title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </section>
);

export default HomeScreen;
