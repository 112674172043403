import { useContext } from 'react';
import { AppContext } from '../../components/AppContext';
import './cta.scss';

const CallToAction = ({ booking, phone }) => {
  const {
    context: {
      settings: {
        cta: { title, text, image },
      },
    },
  } = useContext(AppContext);

  return (
    <section className="call-to-action">
      <div className="container">
        <div
          className="container__inner"
          style={{
            backgroundImage: `url('${image}')`,
          }}
        >
          <h2 className="section-title">{title}</h2>
          {text && <div className="cta__text">{text}</div>}

          <div className="links">
            <a href={booking} rel="noReferrer" target="_blank" className="red-button">
              Book A Service
            </a>

            <span>
              or call us{' '}
              <a href={`tel:+1${phone}`} className="phone">
                {phone}
              </a>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
