import Slider from 'react-slick';

import './brands.scss';

const slider = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const Brands = ({ brands }) => (
  <section className="brands">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">Brands We Repair</h2>
      </div>

      <Slider className="brands__list" {...slider}>
        {brands
          .sort(({ name: a }, { name: b }) => {
            if (a < b) return -1;
            return 1;
          })
          .map(({ logo, name }, index) => (
            <div key={name + index}>
              <img src={logo} alt="brand" />
            </div>
          ))}
      </Slider>

      <ul className="brands__array">
        {brands
          .sort(({ name: a }, { name: b }) => {
            if (a < b) return -1;
            return 1;
          })
          .map(({ name }, index) => (
            <li key={name + index}>{name}</li>
          ))}
      </ul>
    </div>
  </section>
);

export default Brands;
