import { useState, useEffect, createContext } from 'react';
import { toast } from 'react-toastify';
import useFetch from 'react-fetch-hook';

const {
  REACT_APP_DOMAIN: DOMAIN,
  REACT_APP_BOOKING_DOMAIN: BOOKING_DOMAIN
} = process.env;

export const AppContext = createContext({});

const AppContextProvider = ({ children }) => {
  const servicesData = useFetch(`https://${DOMAIN}/wp-json/wp/v2/services?acf_format=standard&per_page=100`);
  const areasData = useFetch(`https://${DOMAIN}/wp-json/wp/v2/areas?acf_format=standard&per_page=100`);
  const generalSettingsData = useFetch(`https://${DOMAIN}/wp-json/wp/v2/general_settings?acf_format=standard`);

  const [state, setState] = useState({ services: null, areas: null, settings: null });

  // Define locations
  useEffect(() => {
    if (areasData.error || servicesData.error || generalSettingsData.error)
      return toast.error('Data loading error. Please reload the page!');

    if (!areasData.isLoading && !servicesData.isLoading) {
      const loadedServices =
        servicesData?.data?.map(
          ({
            id,
            slug,
            title: { rendered: title },
            acf: {text: content , description, main_image: mainImage, about_image: aboutImage, icon },
          }) => {
            return { id, slug, title, content, icon, mainImage, aboutImage, description };
          }
        ) ?? [];

      const loadedAreas = areasData?.data?.map(
        ({
          id,
          slug,
          title: { rendered: title },
          acf: {
            details: { phone, locations, services: areaServices },
          },
        }) => {
          return (
            {
              id,
              slug,
              title,
              phone,
              booking: `https://${BOOKING_DOMAIN}/${slug}`,
              services: loadedServices.filter(({ id: serviceId }) => areaServices.includes(serviceId)),
              locations,
            } ?? []
          );
        }
      );

      setState((state) => {
        return { ...state, services: loadedServices, areas: loadedAreas };
      });
    }

    if (!generalSettingsData.isLoading)
      setState((state) => {
        return { ...state, settings: generalSettingsData?.data?.[0]?.acf };
      });
  }, [areasData, servicesData, generalSettingsData]);

  useEffect(() => {
    console.log(state);
  }, [state]);

  return <AppContext.Provider value={{ context: state }}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
