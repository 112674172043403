import Slider from 'react-slick';

import './reviews.scss';

const slider = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Reviews = ({ reviews }) => (
  <section className="reviews">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">Reviews</h2>
      </div>

      <Slider className="reviews__list" {...slider}>
        {reviews.map((review, index) => (
          <ReviewCard {...{ review, key: index }} />
        ))}
      </Slider>

      <div className="ticker">
        <div className="ticker__text">
          <p>HVAC Repair Services</p>
          <div className="circle" />
          <p>Same Day</p>
          <div className="circle" />
          <p>HVAC Repair Services</p>
          <div className="circle" />
          <p>Same Day</p>
        </div>
      </div>
    </div>
  </section>
);

export default Reviews;

const ReviewCard = ({ review: { name, text, source, date } }) => {
  let icon;

  switch (source) {
    case 'Facebook':
      icon = '/assets/images/f_logo.svg';
      break;
    case 'Yelp':
      icon = '/assets/images/y_logo.svg';
      break;
    default:
      icon = '/assets/images/g_logo.svg';
      break;
  }

  return (
    <div className="reviews__list--card">
      <div className="reviews__list--card__header">
        <div className="icon">
          <img src={icon} alt={source} />
        </div>

        <div className="stars">
          <img src="/assets/images/star_icon.svg" alt="star" />
          <img src="/assets/images/star_icon.svg" alt="star" />
          <img src="/assets/images/star_icon.svg" alt="star" />
          <img src="/assets/images/star_icon.svg" alt="star" />
          <img src="/assets/images/star_icon.svg" alt="star" />
        </div>
      </div>

      <div className="reviews__list--card__info">
        <div className="name">{name}</div>

        <div className="date">{date}</div>
      </div>

      <p>{text}</p>
    </div>
  );
};
