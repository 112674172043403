import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../AppContext';

import './footer.scss';

const Footer = ({ location, phone }) => {
  const {
    context: {
      settings: { logo_footer: logoFooter, logo, brand, email, accessibility, policy, tos },
    },
  } = useContext(AppContext);

  return (
    <footer className="page-footer">
      <div className="container">
        <div className="container__inner">
          <Link to={`/${location || ''}`} className="page-footer__logo">
            <img src={logoFooter || logo} alt={brand} />
          </Link>

          {phone && (
            <a href={`tel:+1${phone}`} className="phone">
              {phone}
            </a>
          )}

          <a href={`mailto:${email}`} className="email">
            {email}
          </a>

          {(accessibility.title || policy.title || tos.title) && (
            <div className="links">
              {policy.title && <Link to="/privacy-policy">Privacy Policy</Link>}
              {tos.title && <Link to="/terms-of-use">Terms Of Use</Link>}
              {accessibility.title && <Link to="/accessibility-statement">Accessibility Statement</Link>}
            </div>
          )}

          <Underline brand={brand} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const Underline = ({ brand }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="page-footer__underline">
      <div className="container">
        <span>
          © {currentYear} {brand} Repair. All rights reserved.
        </span>
      </div>
    </div>
  );
};
