import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../AppContext';

import './header.scss';

const Header = ({ phone, booking, location }) => {
  const {
    context: {
      settings: { logo, brand },
    },
  } = useContext(AppContext);

  const [shadow, setShadow] = useState(false);

  const handleScroll = () => {
    setShadow(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`page-header ${shadow && 'shadow'}`}>
      <div className="container">
        <div className="mobile-btn">
          <a href={`tel:${phone}`} className="page-header__phone">
            {phone}
          </a>
        </div>
        <div className="container__inner">
          <Link to={`/${location || ''}`} className="page-header__logo">
            <img src={logo} alt={brand} />
          </Link>

          <div className="page-header__links">
            {phone && (
              <a href={`tel:+1${phone}`} className="page-header__phone">
                {phone}
              </a>
            )}
            {booking && (
              <a href={booking} rel="noReferrer" target="_blank" className="red-button">
                Book now
              </a>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
