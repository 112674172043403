import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Seo, Header, Footer } from '..';

import './layout.scss';

const Layout = ({ brand, id, phone, email, booking, location, service, slug, social, logoFooter, children, logo }) => {
  const [isVisible, setVisibility] = useState(false);

  const handleScroll = () => {
    setVisibility(window.scrollY > 250);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [service]);

  return (
    <div id={id}>
      <Seo
        data={{
          title: `${brand} | ${location} | ${service}`,
        }}
      />
      <Header phone={phone} booking={booking} location={slug} social={social} logo={logo} />
      <main>{children}</main>
      <Footer phone={phone} location={slug} email={email} brand={brand} logo={logoFooter} />
      <ToastContainer />
      {phone && booking && (
        <div className={`mobile-cta-buttons ${isVisible ? 'on' : 'off'}`}>
          <a href={`tel:+1${phone}`}>{phone}</a>
          <a href={booking} rel="noReferrer" target="_blank">
            Book Online
          </a>
        </div>
      )}
    </div>
  );
};

export default Layout;
